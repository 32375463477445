import { AR } from '@/constants/locales';

export default function useAnimationHelper() {
  const i18n = useI18n();

  const getXValue = ($value: number) => {
    if (i18n.locale === AR) {
      return -$value;
    }

    return $value;
  };

  return {
    getXValue,
  };
}
